import React from 'react'
import { graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CustomBreadCrumb from 'components/Breadcrumb'
import GravityForm from 'components/GravityForm/GravityForm'

// Img
import VacatureIcon from 'img/vacature_icon.inline.svg'

// Third Party
import parse from 'html-react-parser'
import styled from 'styled-components'

const ContentContainer = styled.div`
  h2 {
    color: ${props => props.theme.color.contrast};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size.xm};
    padding-top: 15px;
  }
`

const StyledVacature = styled.div``

const StyledImg = styled(Plaatjie)`
  pointer-events: none;
  height: 100%;
  z-index: -1;
`

const ImageContainer = styled.div`
  height: 330px;

  @media screen and (max-width: 767px) {
    height: 230px;
  }

  @media screen and (max-width: 483px) {
    height: 170px;
  }
`

const ImageContainerText = styled.div`
  margin-top: -100px;
  color: ${props => props.theme.color.text.light};
  line-height: 30px;

  @media screen and (max-width: 991px) {
    margin-top: -100px;
  }

  @media screen and (max-width: 767px) {
    margin-top: -80px;
  }
`

const VacatureContainer = styled.div`
  margin-bottom: 150px;
  position: relative;
  z-index: 50;
`

const VacatureForm = styled.div`
  background-color: ${props => props.theme.color.beige};
  padding: 40px 40px 40px 40px;
  position: relative;
  top: 50px;
  border-radius: 10px;

  @media screen and (max-width: 575px) {
    padding: 20px 20px 20px 20px;
  }
`

const FormTitle = styled.h2`
  font-size: ${props => props.theme.font.size.ml};
  font-weight: ${props => props.theme.font.weight.xl};
  padding-left: 140px;
  padding-top: 30px;

  @media screen and (max-width: 991px) {
    padding-top: 50px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 180px;
  }
`

const StyledVacatureIcon = styled(VacatureIcon)`
  position: absolute;
  left: 40px;
  top: -22px;
  z-index: 1;

  @media screen and (max-width: 991px) {
    top: -2px;
  }

  @media screen and (max-width: 417px) {
    top: 31px;
  }
`

export const VacatureTemplate = ({
    title,
    featuredImage,
    content,
    pageContext
}) => {
    return (
      <StyledVacature>
        <ImageContainer>
          <StyledImg className="d-flex" alt="" image={featuredImage} />
          <ImageContainerText className="container">
            <div className="title pb-lg-2 pb-1 font-size-xxl font-weight-xl">{parse(title)}</div>
          </ImageContainerText>
        </ImageContainer>
        <CustomBreadCrumb data={title} className="py-2" pageContext={pageContext} />
        <VacatureContainer className="container">
          <div className="row">
            <div className="col-lg-7 px-md-5">
              <ContentContainer className="pt-5">
                {parse(content)}
              </ContentContainer>
            </div>
            <div className="col-lg-5 px-md-5 position-relative">
              <FormTitle>Solliciteer direct!</FormTitle>
              <StyledVacatureIcon />
              <VacatureForm className="d-flex justify-content-center">
                <GravityForm vacature id={2} />
              </VacatureForm>
            </div>
          </div>
        </VacatureContainer>
      </StyledVacature>
    )
}

const Vacature = ({ data, pageContext }) => {
    const { page: vacature } = data

    return (
      <Layout>
        {data.page.path !== '/preview' && <SEO yoast={{ meta: vacature.yoastMeta }} pathname={vacature.path} article />}
        <VacatureTemplate
          featuredImage={vacature.acf.banner.image}
          title={vacature.title}
          content={vacature.acf.information.description}
          pageContext={pageContext}
        />
      </Layout>
    )
}

export default Vacature

export const pageQuery = graphql`
  query DetailVacatureQuery($id: String!) {
    page: wordpressWpVacature(id: {eq: $id}) {
      id
      title
      path
      slug
      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        information {
          description
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`